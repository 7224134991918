import { Col, Row, Select, Skeleton, Table,Popconfirm, Form, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { ReportsApi } from "api/api";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import { sortDirections } from "constants/constants";
import { CardToolbox, Main, TableWrapper } from "container/styled";
import { useTableSorting } from "hooks/useTableSorting";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "hooks/useAuthorization";
import { Link } from "react-router-dom";
import moment from "moment";
import { Option } from "antd/lib/mentions";
import { ExportButtonPageApiHeader } from "components/buttons/export-button/export-button-api";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { Button } from "components/buttons/buttons";
import MVTEOImportExportListPage from "pages/quantities-of-refrigerants/components/MVTEOImportExportListPage";
const reportsApi = new ReportsApi();

type RequestStateType = {
  search?: string;
  pageNumber?: number;
  pageSize?: number;
};

const generateYearOptions = (): number[] => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear - 1; year >= 2021; year--) {
    years.push(year);
  }
  return years;
};

const MVTEOAnnualReportOnImportExportSubstances = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();
  /**
   * Authorization
   */
  const { hasPermission } = useAuthorization();
  /**
   * States
   */
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const { onSorterChange, sorting } = useTableSorting();
  const [selectedYear, setSelectedYear] = useState<any>(new Date().getFullYear());
  const [isDescending, setIsDescending] = useState<boolean>(true);
  const [searchValue,setSearchValue] = useState("");
  const [query, setQuery] = useState({
    from: null,
    to: null,
    endOpen: false,
  });

  const [disabledDate, setDisabledDate] = useState({
    startDate: false,
    endDate: false,
  });
  const [request, setRequest] = useState<RequestStateType>({
    search: "",
    pageNumber: 1,
    pageSize: 10,
  });

     // Function to toggle sorting direction
     const toggleSorting = () => {
      setIsDescending((prevState) => !prevState);
    };
  

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, sorting]);

  const handlePaginationChange = (pageNumber: number) => {
    setRequest((prevQuery: any) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber: number, pageSize: number) => {
    setRequest((prevQuery: any) => ({ ...prevQuery, pageNumber, pageSize }));
  };


  const handleYearChange = (value: any) => {
    // Check if the value is defined and has the 'value' property
    if (value ) {
      // Access the 'value' property safely
      setSelectedYear(value);
      setSearchValue(value);
      setRequest((prevQuery) => ({ ...prevQuery, search: value }));
   
    } else {
      // Handle the case where the value or value.value is undefined
      console.error("Invalid year value:", value);
    }
  };

 

  const getData = async () => {
    setIsLoading(true);
    toggleSorting();
    const modifiedSorting = {
      ...sorting,
      sortingIsDescending: isDescending,
    };
    const { data } = await reportsApi.apiReportsImportExportSubstancesGet({
      ...request, ...modifiedSorting 
    });

    setData(data.items);
    setIsLoading(false);
  };


  const columns: ColumnsType<any> = [
    {
      title: t("global.ordinal-number", "No."),
      dataIndex: "ordinalNumber",
      key: "ordinalNumber",
      sorter: false,
    },
    {
      title: t("reports:kgh-service-company-name", "KGH service company name"),
      dataIndex: "organizationName",
      key: "organizationName",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.year", "Year"),
      dataIndex: "year",
      key: "year",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.submitting-date", "Submitting date"),
      dataIndex: "submitedDate",
      key: "submitedDate",
      sorter: true,
      sortDirections,
    },
    {
      title: t("requests:table.person-who-submitted", "Person who submitted"),
      dataIndex: "responsiblePerson",
      key: "responsiblePerson",
      sorter: true,
      sortDirections,
    },
    {
      title: t("users:table.title.action", "Action"),
      dataIndex: "action",
      key: "action",
      width: "90px",
    },
  ];

  const isInitialLoading = isLoading && !data?.items;
  /**
   * Generate table data
   */
  const generateTableData = () => {
    return (data ?? []).map((item: any) => {
      const { id, organizationName, year,ordinalNumber, submitedDate, responsiblePerson } = item;

      return {
        key: id,
        id,
        ordinalNumber:ordinalNumber,
        organizationName,
        year,
        submitedDate:moment(submitedDate).format("MM.DD.yyyy"),
        responsiblePerson,
        action: (
          <div className="table-actions" style={{ clear: "both" }}>
            <Link to={`/reports/view-mvteo-report-by-import-export-substance/${id}`}>
            <Tooltip title={t("global.view", "View")}>
              <Button className="btn-icon" type="default" shape="circle">
                <FeatherIcon icon="eye" size={17} />
              </Button>
              </Tooltip>
            </Link>
          </div>
        ),
      };
    });
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t(
            "side-bar:reports.mvteo-annual-report-import-export-of-ozone-depleting-substance",
            "MVTEO Annual Report On Import/Export Of Ozone Depleting Substance"
          )}
        />
      </CardToolbox>
      <Main>
      <Col span={4}>
        <Form.Item
          name="year"
        >
          <Select
            showSearch
            placeholder={t("global.year", "Year")}
            value={selectedYear}
            onChange={handleYearChange}
            // filterOption={(input: any, option: any) =>
            //   option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
          >
            {generateYearOptions().map((year: any) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        </Col>
        <MVTEOImportExportListPage searchValue={searchValue}/>
        <br /> <br />
        <Row>
          <PageHeader
        ghost
className="report-header"
        buttons={[
          
                       <ExportButtonPageApiHeader key="1" callFrom={'MVTEO Annual Report On Import/Export Of Ozone Depleting Substance 2'} filterType={0} municipalityId={""} entityId={""} search={searchValue}  typeOfEquipmentId={""} from={""} to={""}/>

        
        ]}
      />
      </Row>
      <Row>
          <TableWrapper className="table-responsive">
            {isInitialLoading ? (
              <Cards headless>
                <Skeleton active paragraph={{ rows: 5 }} />
              </Cards>
            ) : (
              <Table
                dataSource={generateTableData()}
                columns={columns}
                showSorterTooltip={false}
                loading={isLoading}
                pagination={{
                  current: data?.pageIndex,
                  total: data?.totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: [10, 50, 100, 1000],
                  onChange: handlePaginationChange,
                  onShowSizeChange: onShowSizeChange,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={(_, __, sorter) => onSorterChange(sorter)}
              />
            )}
          </TableWrapper>
        </Row>
      </Main>
    </>
  );
};

export default MVTEOAnnualReportOnImportExportSubstances;
