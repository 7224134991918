/* tslint:disable */
/* eslint-disable */
/**
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * FunctionalitiesApi - axios parameter creator
 * @export
 */
export const FunctionalitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
  * @param {number} [exportType] 
         * @param {string} [callFrom] 
         * @param {number} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExportDataGet: async (exportType?: number, callFrom?: string, filterType?: number, listArchived?: boolean, timeZone?: string,municipalityId?:string,entityId?:string,search?:string,typeOfEquipmentId?:string,from?:string,to?:string,options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (exportType !== undefined) {
                localVarQueryParameter['ExportType'] = exportType;
            }

            if (callFrom !== undefined) {
                localVarQueryParameter['CallFrom'] = callFrom;
            }


            if (filterType !== undefined) {
                localVarQueryParameter['FilterType'] = filterType;
            }

            if (listArchived !== undefined) {
                localVarQueryParameter['ListArchived'] = listArchived;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['TimeZone'] = timeZone;
            }
            if (municipalityId !== undefined) {
                localVarQueryParameter['MunicipalityId'] = municipalityId;
            }
            if (entityId !== undefined) {
                localVarQueryParameter['EntityId'] = entityId;
            }
            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }
            if (typeOfEquipmentId !== undefined) {
                localVarQueryParameter['TypeOfEquipmentId'] = typeOfEquipmentId;
            }
            if (from !== undefined) {
                localVarQueryParameter['From'] = from;
            }
            if (to !== undefined) {
                localVarQueryParameter['To'] = to;
            }


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FunctionalitiesApi - functional programming interface
 * @export
 */
export const ExportDataApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FunctionalitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [exportType] 
         * @param {string} [callFrom] 
         * @param {number} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExportDataGet(exportType?: number, callFrom?: string, filterType?: number, listArchived?: boolean, timeZone?: string,municipalityId?:string,entityId?:string,search?:string,typeOfEquipmentId?:string,from?:string,to?:string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExportDataGet(exportType, callFrom, filterType, listArchived, timeZone,municipalityId,entityId,search,typeOfEquipmentId,from,to);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FunctionalitiesApi - factory interface
 * @export
 */
export const ExportDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportDataApiFp(configuration)
    return {
        /**
         * 
        * @param {number} [exportType] 
         * @param {string} [callFrom] 
         * @param {number} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExportDataGet(exportType?: number, callFrom?: string, filterType?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiExportDataGet(exportType, callFrom, filterType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFunctionalitiesGet operation in FunctionalitiesApi.
 * @export
 * @interface ExportDataGetRequest
 */
export interface ExportDataGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExportDataGetRequest
     */
    readonly exportType?: number

    /**
     * 
     * @type {string}
     * @memberof ExportDataGetRequest
     */
    readonly callFrom?: string

    /**
 * 
 * @type {number}
 * @memberof ExportDataGetRequest
 */
    readonly filterType?: number

    /**
* 
* @type {number}
* @memberof ExportDataGetRequest
*/
    readonly listArchived?: boolean

    /**
* 
* @type {number}
* @memberof ExportDataGetRequest
*/
    readonly timeZone?: string

/**
* 
* @type {string}
* @memberof ExportDataGetRequest
*/
readonly municipalityId?: string

/**
* 
* @type {string}
* @memberof ExportDataGetRequest
*/
readonly entityId?: string
/**
* 
* @type {string}
* @memberof ExportDataGetRequest
*/
readonly search?: string
/**
* 
* @type {string}
* @memberof ExportDataGetRequest
*/
readonly typeOfEquipmentId?: string
/**
* 
* @type {string}
* @memberof ExportDataGetRequest
*/
readonly from?: string
/**
* 
* @type {string}
* @memberof ExportDataGetRequest
*/
readonly to?: string

}

/**
 * FunctionalitiesApi - object-oriented interface
 * @export
 * @class FunctionalitiesApi
 * @extends {BaseAPI}
 */
export class ExportDataApi extends BaseAPI {
    /**
     * 
     * @param {ExportDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportDataApi
     */
    public apiExportDataGet(requestParameters: ExportDataGetRequest = {}, options?: AxiosRequestConfig) {
        return ExportDataApiFp(this.configuration).apiExportDataGet(requestParameters.exportType, requestParameters.callFrom, requestParameters.filterType, requestParameters.listArchived, requestParameters.timeZone,requestParameters.municipalityId,requestParameters.entityId,requestParameters.search,requestParameters.typeOfEquipmentId,requestParameters.from,requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }
}
