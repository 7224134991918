import { Button, Checkbox, Dropdown, Form, Input,Menu,Modal, Collapse } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthWrapper } from "./style";
import { AuthApi, LanguagesApi } from "api";
import guideLineData from './guideline.json'

//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { decodeJWT } from "utility/decode-jwt";
import { NavAuth } from "components/utilities/auth-info/auth-info-style";
import { theme } from "config/theme/themeVariables";
import PrivacyPolicy from "./PrivacyPolicy";
import Faq from "./Faq";
import LegislationModal from "./Legislation";

const authApi = new AuthApi();
const { Panel } = Collapse;
const basePath = window.location.hostname === 'localhost' ? "https://localhost:7111/" : "/";
const customPanelStyle = {
  background: "#F8F9FB",
  borderRadius: 3,
  marginBottom: 20,
  border: 0,
  overflow: "hidden",
};
function SignIn() {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [privacyModal,setPrivacyModal] = useState(false);
  const [faqModal,setfaqModal] = useState(false);
  const [lesgnationModal,setLesgnationModal] = useState(false);
  const [languages, setLanguages] = useState<{ i18nCode: { code: string; name: string }, isDefault: boolean }[]>([]);
  const languagesApi = new LanguagesApi();

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    const isLoggedIn : any = localStorage.getItem("user");
    if (isLoggedIn && !(JSON.parse(isLoggedIn)?.shouldShowOnboarding)) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  const getLanguages = async () => {
    const { data }:any = await languagesApi.apiLanguagesGet();
    const lng = localStorage.getItem("i18nextLng");
    setLanguages(data);

    if (lng === "en-US") {
      // eslint-disable-next-line array-callback-return
      data?.map((res:any) => {
        if (res.isDefault) {
          i18n.changeLanguage(res.i18nCode.code);
        }
      });
    }
  };

  const menuItems = languages.map((language: any) => {
    const isDefaultLanguage = language?.i18nCode?.code === i18n?.language;
    return {
      key: language?.i18nCode?.code,
      label: (
        <Link
          onClick={() => onFlagChangeHandle(language?.i18nCode?.code)}
          to="#"
          style={{
            backgroundColor: isDefaultLanguage ? theme["linkActive"] : "",
          }}
        >
          <span className={isDefaultLanguage ? "ant-menu-item-selected" : ""}>
            {language?.i18nCode?.name}
          </span>
        </Link>
      ),
    };
  });

  const country = { items: menuItems };

  const onFlagChangeHandle = (value:any) => {
    try {
      i18n.changeLanguage(value);
      const languageObj =
        languages.find((x) => x?.i18nCode?.code === value) || null;
        

      if (!languageObj) return;

    
    } catch (err) {}
  };


  const handleFormSubmit = async () => {
    try {
      setIsLoading(true);

      const payload = form.getFieldsValue();

      const { data } = await authApi.apiAuthLoginPost({
        loginCommand: { ...payload, rememberMe },
      });

      localStorage.setItem("user", JSON.stringify(data));

      const token: any = decodeJWT();
      if (token) {
        const languagecode = token?.language;
        i18n.changeLanguage(languagecode);
      }

      let navigateTo = "/";
      if (data.shouldShowOnboarding) navigateTo = "/set-new-password";

      navigate(navigateTo, { replace: true });
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <AuthWrapper>
      <div className="nav-author" style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              flexDirection: "row",
            }}>
        <Dropdown placement="bottomCenter" menu={country}>
          <Link
            to="#"
            className="head-example"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <FeatherIcon icon="globe" />
            <p style={{ fontWeight: 500, margin: 0, paddingLeft: 5 }}>
              {localStorage.getItem("i18nextLng")?.toUpperCase() || ''}
            </p>
          </Link>
        </Dropdown>
      </div>
      <div className="auth-contents">
        <Form
          name="signIn"
          form={form}
          style={{ paddingTop: 100 }}
          onFinish={handleFormSubmit}
          layout="vertical"
        >
          <div style={{ fontWeight: "bold", fontSize: 40 }}>{t("signIn.companyName", { defaultValue: "KGH" })}</div>
          <div
            style={{ fontWeight: "bold", fontSize: 30, paddingBottom: 10 }}
            className="color-secondary"
          >
            {t("signIn.WebPlatform", { defaultValue: "Web Platform" })}
          </div>
          <Form.Item
            name="email"
            rules={[
              {
                message: t("signIn.emailRequired", {
                  defaultValue: "Please input your Email!",
                }),
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t("signIn.emailPlaceholder", {
                defaultValue: "Email",
              })}
              prefix={<FeatherIcon icon="mail" size={14} />}
            />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password
              placeholder={t("signIn.passwordPlaceholder", {
                defaultValue: "Password",
              })}
              prefix={<FeatherIcon icon="lock" size={14} />}
            />
          </Form.Item>
          <div className="auth-form-action">
            <Checkbox onChange={() => setRememberMe(!rememberMe)}>
              {t("signIn.remember-me", { defaultValue: "Remember me" })}
            </Checkbox>
            <NavLink className="forgot-pass-link" to="/forgot-password">
              {t("signIn.forgotPassword", {
                defaultValue: "Forgot password?",
              })}
            </NavLink>
          </div>
          <Form.Item>
            <Button
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              disabled={isLoading}
            >
              {isLoading
                ? t("signIn.loading", { defaultValue: "Loading..." })
                : t("signIn.signIn", { defaultValue: "Sign In" })}
            </Button>
          </Form.Item>
          <p className="form-divider" style={{ marginTop: 50 }}>
            <span>{t("signIn.or-read", { defaultValue: "or read" })}</span>
          </p>
        </Form>
      </div>
      <div className="auth-contents">
        <ul className="social-login">
          <li>
            <Link to="#" onClick={() => setfaqModal(true)}>
              <span>{t("signIn.FAQ", { defaultValue: "FAQ" })}</span>
            </Link>
          </li>
          <li>
          <Link to="#" onClick={() => setShowModal(true)}>
              <span>{t("signIn.Guidelines", { defaultValue: "Guidelines" })}</span>
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => setLesgnationModal(true)}>
              <span>{t("signIn.Legislation", { defaultValue: "Legislation" })}</span>
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => setPrivacyModal(true)}>
              <span>{t("signIn.Privacy Policy", { defaultValue: "Privacy Policy" })}</span>
            </Link>
          </li>
        </ul>
      </div>
      <Modal
        title={"Guidelines"}
        visible={showModal}
        width={1000}
        className="guildlines-modal"
        footer={false}
        onCancel={() => setShowModal(!showModal)}
      >
        <div style={{display:'grid',gridTemplateColumns:'auto auto'}}>
          {guideLineData.map((module) => (
            <Collapse style={{margin:'20px'}}  bordered={false}>
              <Panel
                header={
                  
                  // <strong>{module.name}</strong>
                  <strong>{t(module.translationKey, { defaultValue: module.name })}</strong>

                }
                key="1"
                style={customPanelStyle}
                forceRender
              >
                {module.filesArray.map((x) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                    }}
                  >
                    <div
                      onClick={() =>
                        window.open(basePath+x.filename, "_blank")}

                    >
                      <strong><a>{x.language}</a></strong>
                    </div>
                  </div>
                ))}
              </Panel>
            </Collapse>
          ))}
        </div>
      </Modal>
      <PrivacyPolicy showModal={privacyModal} setShowModal={setPrivacyModal} />
      <Faq faqModal={faqModal} setfaqModal={setfaqModal} />
      <LegislationModal showModal={lesgnationModal} setShowModal={setLesgnationModal} />

    </AuthWrapper>
  );
}

export default SignIn;
