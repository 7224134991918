import { Card, Col, DatePicker, Input, Row, Spin } from "antd";
import { RequestsApi } from "api/api";
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
    CardToolbox,
    DatePickerWrapper,
    FileCardWrapper,
    Main,
} from "container/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { ApproveRequestDto, RequestDetailsDto, RequestStatus, RequestType } from "api/models";
import moment from "moment";
import { humanFileSize, shortenFileName } from "utility/utility";
import styled from "styled-components";
import renderStatusBadge from "components/tags/requestStatusTag";
import TextArea from "antd/lib/input/TextArea";
import openNotificationWithIcon from "utility/notification";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";
import loggedAsCompanyUser from "utility/loggedAsCompanyUser";

const Loader = styled.div`
    display: flex; 
    height: 400px;
    width: 100%; 
    justify-content: center; 
    justifyItems: center; 
    align-items: center
`

const CardKeyValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    color: #323548;
    font-size: 13px;
    display: block;
    font-weight:bold;
    margin-bottom: 3px;
  }

  p {
    font-weight: 500;
  }
`;


const requestsApi = new RequestsApi();

export const RequestDetailsPage = () => {

    const params = useParams();
    const [requestData, setRequestData] = useState<RequestDetailsDto>({});
    const [requestLoading, setRequestLoading] = useState(false);
    const [isRegistrationOfServiceCompany, setIsRegistrationOfServiceCompany] = useState(false);
    const [isRegistrationOfImportersExporters, setIsRegistrationOfImportersExporters] = useState(false);
    const [isRequestForLicenseExtension, setIsRequestForLicenseExtension] = useState(false);
    const [requestActData, setRequestActData] = useState<ApproveRequestDto>({});
    const [isApprovingRequest, setIsApprovingRequest] = useState(false);
    const [isRejectingRequest, setIsRejectingRequest] = useState(false);
    const [isInPendingStatus, setIsInPendingStatus] = useState(false);
    const [licenseDurationValid, setLicenseDurationValid] = useState(false);
    const currentUserIsCompany = loggedAsCompanyUser();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                setRequestLoading(true);
                const response = await requestsApi.apiRequestsIdGet({ id: params.id as string });
                const responseData = response.data;
                setRequestData(responseData);
                setRequestActData({
                    licenseId: responseData.licenseId,
                    licenseDuration: responseData.licenseDuration,
                });
                setIsRegistrationOfServiceCompany(responseData.requestType === RequestType.RegistrationAndLicensingOfServiceCompanies);
                setIsRegistrationOfImportersExporters(responseData.requestType === RequestType.RegistrationAndLicensingOfImportersExporters);
                setIsRequestForLicenseExtension(responseData.requestType === RequestType.RequestForExtensionOfLicenseOfServiceCompanies);
                setIsInPendingStatus(responseData.status === RequestStatus.PENDING);
            } catch (error) {
                console.error(error);
            } finally {
                setRequestLoading(false);
            }
        };

        if (params.id)
            fetchDetails();
    }, [params.id]);

    const downloadFile = (fileId: string, contentType: string) => {
        requestsApi.apiRequestsDownloadFileIdGet({ fileId: fileId }, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileId);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleInputChange = (key: keyof ApproveRequestDto, event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const updatedData = { ...requestActData, [key]: event.target.value };
        setRequestActData(updatedData);
    }

    const onDateChange = (date: moment.Moment | null, key: any) => {
        // const data = moment(date, "DD.MM.yyyy").format("MM/DD/yyyy");
        const updatedData = { ...requestActData, [key]: date };
        setRequestActData(updatedData);
        key === 'licenseDuration' && setLicenseDurationValid(date !== null);
    };

    const renderLicenseDuration = () => {
        return (
            <CardKeyValue>
                <span>{t("requests:details.license-duration", "License Duration")}</span>
                {(requestData.status !== RequestStatus.PENDING || currentUserIsCompany) && (
                    <p>{requestData.licenseDuration ? moment(requestData.licenseDuration).format('MM.DD.yyyy') : '-'}</p>
                )}
                {isInPendingStatus && !currentUserIsCompany &&
                    <DatePickerWrapper style={{ height: "38px", width: '75%' }}>
                        <DatePicker
                            status={licenseDurationValid ? '' : 'error'}
                            onChange={(data) => onDateChange(data, 'licenseDuration')}
                            aria-required={true}
                            value={requestActData.licenseDuration ? moment(requestActData.licenseDuration) : null}
                            suffixIcon={<FeatherIcon icon="calendar" size={14} />}
                            format={"DD.MM.YYYY"}
                        />
                    </DatePickerWrapper>
                }
            </CardKeyValue>
        )
    }

    const checkLicenseValidations = () => {
        let isValid = true;
        if (!requestActData.licenseId &&
            (isRegistrationOfServiceCompany || isRegistrationOfImportersExporters)) {
            openNotificationWithIcon(
                "error",
                t('approve-request:license-id-required', 'License ID is required')
            );
            isValid = false;
        }

        if (!licenseDurationValid &&
            (isRegistrationOfServiceCompany || isRegistrationOfImportersExporters || isRequestForLicenseExtension)) {
            openNotificationWithIcon(
                "error",
                t('approve-request:license-expiration-date-required', 'License duration is required')
            );
            isValid = false;
        }

        return isValid;
    }

    const approveRequest = async () => {
        const licenseInputsValid = checkLicenseValidations();
        if (!licenseInputsValid) return;

        try {
            setIsApprovingRequest(true);
            await requestsApi.apiRequestsIdApprovePut({ id: params.id as string, approveRequestDto: requestActData });
            navigate("/requests");
            openNotificationWithIcon(
                "success",
                t("requests:success-approval", "Request approved successfully")
            );

        } catch (error) {
            // openNotificationWithIcon(
            //     "error",
            //     t('requests:error-approval', 'Request failed to be approved')
            // );
        } finally {
            setIsApprovingRequest(false);
        }
    }

    const rejectRequest = async () => {
        try {
            setIsRejectingRequest(true);
            await requestsApi.apiRequestsIdRejectPut({ id: params.id as string, body: requestActData.comments || '' });
            navigate("/requests");
            openNotificationWithIcon(
                "success",
                t("requests:success-reject", "Request rejected successfully")
            );
        } catch (error) {
            openNotificationWithIcon(
                "error",
                t('requests:error-reject', 'Request failed to be rejected')
            );
        } finally {
            setIsRejectingRequest(false);
        }
    }

    return (
        <>{
            requestLoading
                ? <Loader><Spin /> </Loader>
                :
                <>
                    <CardToolbox>
                        <PageHeader title={t('requests:details-title', 'Details of request')} />
                    </CardToolbox>
                    <Main>
                        <Card>
                            <Row>
                                <Col xs={8} style={{ paddingRight: 10}}>
                                    <CardKeyValue>
                                        <span> {t("requests:details.register-number", "Register number")}</span>
                                        <p>{requestData.requestId}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.legal-entity-name", "Name of the legal entity/entrepreneur")}</span>
                                        <p>{requestData.contactPerson}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.company-name", "Company name")}</span>
                                        <p>{requestData.companyName}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.id-number", "Id number")}</span>
                                        <p>{requestData.idNumber}</p>
                                    </CardKeyValue> 
                                    <CardKeyValue>
                                        <span>{t("requests:details.responsible-person", "Responsible person")}</span>
                                        <p>{requestData.responsiblePersonFullName}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.responsible-person-function", "Responsible person function")}</span>
                                        <p>{requestData.responsiblePersonFunction}</p>
                                    </CardKeyValue>
                                    {isRegistrationOfServiceCompany && (
                                        <CardKeyValue>
                                            <span>{t("requests:details.company-type", "Company type")}</span>
                                            <p>{requestData.companyType}</p>
                                        </CardKeyValue>
                                    )}
                                    <CardKeyValue>
                                        <span>{t("requests:details.address", "Headquarter (address)")}</span>
                                        <p>{requestData.address || '-'}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.place-city", "Place (city)")}</span>
                                        <p>{requestData.place || '-'}</p>
                                    </CardKeyValue>
                                    {/* <CardKeyValue>
                                        <span>{t("requests:details.post-code", "Mailing address")}</span>
                                        <p>{requestData.postCode || '-'}</p>
                                    </CardKeyValue> */}
                                 
                                    <CardKeyValue>
                                        <span>{t("global.municipality", "Municipality")}</span>
                                        <p>{requestData.municipality}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("global.canton", "Canton")}</span>
                                        <p>{requestData.canton}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("global.entity", "Entity")}</span>
                                        <p>{requestData.entity}</p>
                                    </CardKeyValue>
                                </Col>
                                <Col xs={8} style={{ paddingRight: 10}}>
                                    
                                    <CardKeyValue>
                                        <span>{t("requests:details.request-type", "Type of request")}</span>
                                        <p>{requestData.requestTypeDesc}</p>
                                    </CardKeyValue>
                                  
                                    <CardKeyValue>
                                        <span>{t("requests:details.phone-number", "Phone number")}</span>
                                        <p>{requestData.companyPhoneNumber || '-'}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.tax-number", "Tax number")}</span>
                                        <p>{requestData.taxNumber || '-'}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.area-of-expertise", "Area of Expertise")}</span>
                                        <p>{requestData.areaOfExpertise || '-'}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.email", "Email address")}</span>
                                        <p>{requestData.companyEmailAddress}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.website", "Website")}</span>
                                        <p>{requestData.websiteUrl || '-'}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.contact-person", "Contact person")}</span>
                                        <p>{requestData.contactPerson}</p>
                                    </CardKeyValue>
                                    <CardKeyValue>
                                        <span>{t("requests:details.contact-person-email", "Contact person Email")}</span>
                                        <p>{requestData.contactPersonEmail}</p>
                                    </CardKeyValue>
                                    {isRegistrationOfServiceCompany && (
                                        <CardKeyValue>
                                            <span>{t("requests:details.activity", "Activity performed by a legal entity - an entrepreneur")}</span>
                                            <p>{requestData.areaOfExpertise}</p>
                                        </CardKeyValue>
                                    )}
                                    <CardKeyValue>
                                        <span>{t("requests:details.date-of-register", "Registration date")}</span>
                                        <p>{moment(requestData.createdAt).format('MM.DD.yyyy')}</p>
                                    </CardKeyValue>
                                    {!isInPendingStatus &&
                                        <>
                                            <CardKeyValue>
                                                <span>{t("requests:details.reviewed-by", "Reviewed by")}</span>
                                                <p>{requestData.reviewedByName || '-'}</p>
                                            </CardKeyValue>
                                            <CardKeyValue>
                                                <span>{t("requests:details.date-of-review", "Review date")}</span>
                                                <p>{requestData.reviewedAt
                                                    ? moment(requestData.reviewedAt).format('MM.DD.yyyy')
                                                    : '-'}</p>
                                            </CardKeyValue>
                                        </>}
                                </Col>
                                <Col xs={8}  style={{ paddingRight: 10}}>
                                    <CardKeyValue>
                                        <span>{t("requests:details.attachments", "Attached documents")}</span>
                                        <FileCardWrapper style={{ display: 'flex' }}>
                                            <div className="file-list">
                                                {requestData.attachments?.map((item, index) => (<div className="file-list__single d-flex">
                                                    <div className="file-single-info d-flex">
                                                        <div className="file-single-logo">
                                                            <FeatherIcon icon="file" size={16} />
                                                        </div>
                                                        <div className="file-single__content">
                                                            <span className="file-name">{shortenFileName(item.fileName)}</span>
                                                            <span className="file-size">{humanFileSize(item.size)}</span>
                                                            <span className="file-content-action">
                                                                <span
                                                                    onClick={() => downloadFile(item.id as string, item.contentType as string)}>
                                                                    Download
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>))}
                                            </div>
                                        </FileCardWrapper>
                                    </CardKeyValue>
                                    <CardKeyValue className="mt-3">
                                        <span>{t("requests:details.status", "Status")}</span>
                                        <p style={{ width: '30%' }}>{renderStatusBadge(requestData.status as RequestStatus,
                                            t(`requests:status.${requestData.status}`, requestData.statusDesc as string))}</p>
                                    </CardKeyValue>
                                    {isRegistrationOfServiceCompany && (
                                        <CardKeyValue>
                                            <span>{t("requests:details.has-needed-equipment-check", "Has needed equipment according to the proper regulations")}</span>
                                            {requestData.meetsEquipmentRegulations != null && (
                                                <FeatherIcon icon={requestData.meetsEquipmentRegulations ? 'check' : 'x'} size={16} />
                                            )}
                                            {requestData.meetsEquipmentRegulations == null && (
                                                <FeatherIcon icon={'minus'} size={16} />
                                            )}
                                        </CardKeyValue>
                                    )}
                                    {isRegistrationOfServiceCompany && (
                                        <>
                                            <CardKeyValue className="mt-3">
                                                <span>{t("requests:details.count-of-certified-technicians", "Number of certified service technicians")}</span>
                                                {requestData.certificationNumbers?.map((x, i) => (
                                                    <label>{i + 1}. {x.certificateNumber} {x.certifiedTechnicianFullName && (`(${x.certifiedTechnicianFullName})`)}<br /></label>
                                                ))}
                                            </CardKeyValue>
                                            <CardKeyValue>
                                                <span>{t("requests:details.total-no-service-technicians", "Total number of service technians")}</span>
                                                <p>{requestData.totalNumberOfServiceTechnians}</p>
                                            </CardKeyValue>
                                        </>
                                    )}
                                    {isRegistrationOfServiceCompany && requestData.businessActivityId && (
                                        <CardKeyValue>
                                            <span>{t("requests:details.business-activity", "Business activity")}</span>
                                            <p>{t(`business-activity-${requestData.businessActivityId}`, requestData.businessActivity as string)}</p>
                                        </CardKeyValue>
                                    )}
                                    {isRegistrationOfServiceCompany && (
                                        <CardKeyValue>
                                            <span>{t("requests:details.activity", "Activity performed by a legal entity - an entrepreneur")}</span>
                                            <p>{requestData.areaOfExpertise}</p>
                                        </CardKeyValue>
                                    )}
                                    {(isRegistrationOfServiceCompany || isRegistrationOfImportersExporters) && (
                                        <>
                                            <CardKeyValue>
                                                <span>{t("requests:details.license-id", "License Id")}</span>
                                                {(requestData.status !== RequestStatus.PENDING || currentUserIsCompany) &&
                                                    <p>{requestData.licenseId || '-'}</p>
                                                }
                                                {isInPendingStatus && !currentUserIsCompany && (
                                                    <Input
                                                        required
                                                        style={{ height: "38px", width: '75%' }}
                                                        value={requestActData.licenseId || ''}
                                                        onChange={e => handleInputChange('licenseId', e)}
                                                    />
                                                )}
                                            </CardKeyValue>
                                            {renderLicenseDuration()}
                                        </>
                                    )}
                                    {isRequestForLicenseExtension && renderLicenseDuration()}
                                    <CardKeyValue>
                                        {!isInPendingStatus &&
                                            <>
                                                <span>{t("requests:details.approve-comments", "Approve Comments")}</span>
                                                <i>{requestData.comments || ''}</i>
                                            </>
                                        }
                                        {isInPendingStatus && (
                                            <>
                                                <span>{t("requests:details.approve-comments", "Approve Comments")}</span>
                                                <i>{requestData.comments || ''}</i>
                                                {hasPermission("requests:approve") && (<TextArea
                                                    style={{ width: '100%' }}
                                                    rows={3}
                                                    onChange={e => handleInputChange('comments', e)}
                                                />)}
                                            </>
                                        )}

                                    </CardKeyValue>
                                    {isInPendingStatus && (
                                        <div
                                            className="mt-5 approve"
                                            style={{ display: "flex", gap: 20, justifyContent: "flex-end" }}
                                        >
                                            {hasPermission("requests:approve") &&
                                                <Button
                                                    type="success"
                                                    loading={isApprovingRequest}
                                                    disabled={isRejectingRequest}
                                                    onClick={approveRequest}
                                                >
                                                    {t('requests:details.approve', 'Approve')}
                                                </Button>
                                            }
                                            {hasPermission("requests:reject") &&
                                                <Button
                                                    type="danger"
                                                    loading={isRejectingRequest}
                                                    disabled={isApprovingRequest}
                                                    onClick={rejectRequest}
                                                >
                                                    {t('requests:details.reject', 'Reject')}
                                                </Button>
                                            }
                                        </div>
                                    )}
                                </Col>
                            </Row>

                        </Card>
                    </Main>
                </>
        }
        </>
    );
};
