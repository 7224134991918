import { useContext, useEffect, useState } from "react";
import { Form, Button, Input, Spin, Select } from "antd";
import FeatherIcon from "feather-icons-react";
import { BasicFormWrapper } from "container/styled";
import { useTranslation } from "react-i18next";
import { showServerErrors } from "utility/utility";
import openNotificationWithIcon from "utility/notification";
import { UsersApi } from "api";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
import { hasPermission } from "utility/accessibility/hasPermission";
const { Option } = Select;

const usersApi = new UsersApi();

const EditProfile = ({render}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [userMe, setUserMe] = useState();
  const [_municipalityId, setMunicipalityId] = useState(0);
  const [_entityId, setEntityId] = useState(0);
  const [municipalityList, setMunicipalityList] = useState([]);
  const commonData = useContext(CommonDataContext);
  const {
    municipalities,
    cantons,
    stateEntities,
    isLoading: loadingCommonData,
    languages,
  } = commonData;
  useEffect(() => {
    render && getMe();
  }, [render]);

  useEffect(() => {
    setMunicipalityList(municipalities.filter((item) => item.stateEntityId === _entityId));
    onMunicipalityChange(_municipalityId, _entityId);
  }, [municipalities, _entityId]);

  const getMe = async () => {
    const { data } = await usersApi.usersMeGet();
    form.setFieldsValue(data);

    const { institutionId, municipalityId, cantonId, stateEntityId } = data;
    form.setFieldValue("institutionId", institutionId?.toString());

    setMunicipalityId(municipalityId);
    setEntityId(stateEntityId);

    setUserMe(data);
  };

  const handleSubmit = async ({
    firstName,
    lastName,
    id,
    institutionId,
    municipalityId,
    phoneNumber,
    languageId
  }) => {
    try {
      const payload = {
        firstName,
        lastName,
        id,
        email: userMe.email,
        institutionId,
        municipalityId,
        phoneNumber,
        languageId
      };
      await usersApi.usersMePut({ updateProfileCommand: payload });
      openNotificationWithIcon(
        "success",
        t("profile.success.update", " You have successfully edited your profile information")
      );
    } catch (err) {
      showServerErrors(err);
    }
  };

  const onMunicipalityChange = (value) => {
    const municipality = municipalities.find((item) => item.id === value);
    if (municipality) {
      form.setFieldsValue({
        cantonId: municipality.cantonId,
        stateEntityId: municipality.stateEntityId,
      });
    }
  };

  return (
    <>
      {loadingCommonData ? (
        <div
          style={{
            display: "flex",
            height: 400,
            width: "100%",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <BasicFormWrapper
          style={{ width: "100%", maxWidth: 1200, marginTop: -30 }}
        >
          <Form form={form} name="editProfile" onFinish={handleSubmit}>
            <Form.Item name="id" style={{ height: 0 }}>
              <Input required style={{ display: "none", height: 0 }} />
            </Form.Item>
            <Form.Item
              required
              name="firstName"
              label={t("profile.edit:input.first-name", "First Name")}
            >
              <Input required prefix={<FeatherIcon icon="user" size={14} />} />
            </Form.Item>
            <Form.Item
              required
              name="lastName"
              label={t("profile.edit:input.last-name", "Last Name")}
            >
              <Input required prefix={<FeatherIcon icon="users" size={14} />} />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={t("global.phone-number	", "Phone Number")}
            >
              <Input
                placeholder={t(
                  "global.phone-number	",
                  "Phone Number"
                )}
                prefix={
                  <FeatherIcon icon="phone" size={14} />
                }
              />
            </Form.Item>
            <Form.Item
              required
              name="email"
              label={t("profile.edit:input.email", "Email")}
            >
              <Input required disabled prefix={<FeatherIcon icon="mail" size={14} />} />
            </Form.Item>

            <Form.Item
              name="organizationName"
              label={t("profile:institution", "Institution")}
            >
              <Input disabled prefix={<FeatherIcon icon="server" size={14} />} />
            </Form.Item>

            <Form.Item
            style={{display:'none'}}
              name="municipalityId"
              label="Select Municipality"
              required
              rules={[
                {
                  required: true,
                  message: "Please select Municipality",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
                className="sDash_fullwidth-select"
                style={{ color: "rgb(90, 95, 125)" }}
                aria-required
                onChange={onMunicipalityChange}
              >
                {municipalityList &&
                  municipalityList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item  style={{display:'none'}} name="cantonId" required label="Select Canton">
              <Select
                className="sDash_fullwidth-select"
                aria-required
                style={{ color: "rgb(90, 95, 125)" }}
                disabled
              >
                {cantons &&
                  cantons.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
             style={{display:'none'}}
              name="stateEntityId"
              required
              label="Select Entity"
              requiredMark
              rules={[
                {
                  required: true,
                  message: "Please select Entity",
                },
              ]}
            >
              <Select
                className="sDash_fullwidth-select"
                aria-required
                style={{ color: "rgb(90, 95, 125)" }}
                disabled
              >
                {stateEntities &&
                  stateEntities.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            
            <Form.Item
              name="languageId"
              required
              label={t(
                "system-languages.default-language",
                "Default Language"
              )}
              requiredMark
              rules={[
                {
                  required: true,
                  message: t(
                    "validations:language.required",
                    "Please select language"
                  ),
                },
              ]}
            >
              <Select
                className="sDash_fullwidth-select"
                aria-required
                style={{ color: "rgb(90, 95, 125)" }}
              >
                {languages &&
                  languages.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {hasPermission("profile:edit") && (
              <div className="setting-form-actions">
                <Button size="default" htmlType="submit" type="primary">
                  {t("profile.form.button", "Update Profile")}
                </Button>
              </div>
            )}
          </Form>
        </BasicFormWrapper>
      )}
    </>
  );
};

export default EditProfile;
