import { Col, Row, Skeleton } from "antd";
import { Suspense, useEffect, useState } from "react";

import { UsersApi } from "api/api";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import { Main } from "container/styled";
import SetNewPasswordPage from "pages/authentication/SetNewPasswordPage";
import EditProfile from "pages/profile/EditProfile";
import EmploymentStatusBox from "pages/settings/EmploymentStatusBox";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AuthorBox, { BoxTypes } from "./overview/ProfileAuthorBox";
import { CreateInstitutionModal } from "pages/institutions/components/CreateInstitutionModal";
import { useOrganization } from "utility/useOrganization";
import loggedAsCompanyUser from "utility/loggedAsCompanyUser";
import openNotificationWithIcon from "utility/notification";
import { CompanyInfoForm } from "./overview/CompanyInfoForm";
import TechnicianQualifications from "pages/certified-technicians/components/TechnicianQualifications";
import { QualificationViewMode } from "pages/certified-technicians/components/QualificationForm";

const userApi = new UsersApi();

function Settings() {
  const { t } = useTranslation();
  const [typeOfBox, setTypeOfBox] = useState(BoxTypes.profile);
  const [id, setId] = useState();
  const [showEmploymentStatus, setShowEmploymentStatus] = useState(false);
  const {institution,fetchData} = useOrganization();
  const currentUserIsCompany = loggedAsCompanyUser();

  const handleAuthorBox = (value) => {
    setTypeOfBox(value);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const {
        data: { roleName, id },
      } = await userApi.usersMeGet();
      const isCertifiedTechician = roleName?.includes(
        "Service technicians - individuals"
      );
      setId(id);
      setShowEmploymentStatus(isCertifiedTechician);
    } catch (err) { }
  };

  const renderBoxComponent = () => {
    switch (typeOfBox) {
      case BoxTypes.profile:
        return <EditProfile render={true} />;
      case BoxTypes.companyInfo:
        return <CompanyInfoForm
          id={institution.id}
          isVisible={true}
          currentUserIsCompany={currentUserIsCompany}
          onSubmitSuccess={() => fetchData()}
          institutionToEdit={institution}
        />;
      case BoxTypes.setPassword:
        return <SetNewPasswordPage isOnBoardingMode={false} />;
      case BoxTypes.employmentStatus:
        return <EmploymentStatusBox />;
        case BoxTypes.qualifications:
          return <TechnicianQualifications id={id} hideAdd={true} />;
      default:
        return null;
    }
  };

  const getCardTitle = () => {
    const { employmentStatus, profile, companyInfo, setPassword, qualifications } = BoxTypes;

    const titles = {
      [profile]: t("edit-profile:subtitle", "Set Up Your Personal Information"),
      [companyInfo]: t("company-info:subtitle", "Update your Company Information"),
      [setPassword]: t("password:subtitle", "Change Password"),
      [employmentStatus]: t("common:employment-status", "Employment status"),
      [qualifications]: t("common:employment-qualifications", "Qualifications"),
    };

    return titles[typeOfBox];
  };

  return (
    <>
      <PageHeader ghost title={t('profile:settings', "Profile Settings")} />

      <Main>
        <Row>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar />
                </Cards>
              }
            >
              <AuthorBox
                onClick={handleAuthorBox}
                type={typeOfBox}
                showEmploymentStatus={showEmploymentStatus}
              />
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <Main>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Cards
                  style={{ maxWidth: "100%", width: "100%" }}
                  title={getCardTitle()}
                >
                  <Row justify="center" style={{ maxWidth: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {renderBoxComponent()}
                    </div>
                  </Row>
                </Cards>
              </div>
            </Main>
          </Col>
        </Row>
      </Main>
    </>
  );
}

Settings.propTypes = {
  match: propTypes.object,
};

export default Settings;
