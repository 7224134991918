import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const LegislationModal = ({ showModal, setShowModal }) => {
    const { t } = useTranslation();
    const basePath = window.location.hostname === 'localhost' ? "https://localhost:7111/" : "/";

    const filesArray = [
        {
          "id": 1,
          "language": "Odluka VM Montrealski 67 15",
          "filename": "legislation/Odluka VM Montrealski 67 15.pdf"
        },
        {
          "id": 2,
          "language": "Odluka VM Montrealski br. 36 07",
          "filename": "legislation/Odluka VM Montrealski br. 36 07.pdf"
        },
        {
          "id": 3,
          "language": "Pravilnik o postepenom iskljucivanju supstanci koje ostecu…_0_0",
          "filename": "legislation/Pravilnik o postepenom iskljucivanju supstanci koje ostecu…_0_0.pdf"
        },
        {
          "id": 4,
          "language": "Zakon o zastiti zraka_72_24",
          "filename": "legislation/Zakon o zastiti zraka_72_24.pdf"
        }
      ]
    return <Modal
        title={t("signIn.Legislation", { defaultValue: "Legislation" })}
        visible={showModal}
        width={1000}
        className="Legislation-modal"
        footer={false}
        onCancel={() => setShowModal(!showModal)}
    >
     <div>
     {filesArray.map((x) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                    }}
                  >
                    <div
                      onClick={() =>
                        window.open(basePath+x.filename, "_blank")}

                    >
                      <strong><a>{x.language}</a></strong>
                    </div>
                  </div>
                ))}
     </div>
    </Modal>
}

export default LegislationModal